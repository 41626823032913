self.deprecationWorkflow = self.deprecationWorkflow || {};

/**
 * when ready to get rid of deprecations, change "silence" to "throw", more there
 * https://github.com/mixonic/ember-cli-deprecation-workflow
 */
self.deprecationWorkflow.config = {
  workflow: [
    { handler: "silence", matchId: "ember-simple-auth.initializer.setup-session-restoration" },
    { handler: "silence", matchId: "deprecate-router-events" },
    { handler: "silence", matchId: "ember-cli-mirage-config-routes-only-export" },
    { handler: "silence", matchId: "this-property-fallback" },
    { handler: "silence", matchId: "implicit-injections" },
    { handler: "silence", matchId: "ember-string.prototype-extensions" },
    { handler: "silence", matchId: "ember-source.deprecation-without-for" },
    { handler: "silence", matchId: "ember-source.deprecation-without-since" },
    { handler: "silence", matchId: "ember-data:method-calls-on-destroyed-store" },
    { handler: "silence", matchId: "computed-property.override" },
    { handler: "silence", matchId: "ember-metal.get-with-default" },
    { handler: "silence", matchId: "ember-cli-mirage.miragejs.import" }
  ]
};
