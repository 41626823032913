define("ember-concurrency/-private/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskHelperClosure = taskHelperClosure;
  function taskHelperClosure(helperName, taskMethod, _args, hash) {
    let task = _args[0];
    let outerArgs = _args.slice(1);
    return function () {
      if (!task || typeof task[taskMethod] !== 'function') {
        (true && !(false) && Ember.assert(`The first argument passed to the \`${helperName}\` helper should be a Task object (without quotes); you passed ${task}`, false));
        return;
      }
      for (var _len = arguments.length, innerArgs = new Array(_len), _key = 0; _key < _len; _key++) {
        innerArgs[_key] = arguments[_key];
      }
      if (hash && hash.value) {
        let event = innerArgs.pop();
        innerArgs.push(Ember.get(event, hash.value));
      }
      return task[taskMethod](...outerArgs, ...innerArgs);
    };
  }
});