define("ember-concurrency/-private/ember-environment", ["exports", "ember-concurrency/-private/external/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmberEnvironment = _exports.EMBER_ENVIRONMENT = void 0;
  class EmberEnvironment extends _environment.Environment {
    assert() {
      (true && !(false) && Ember.assert(...arguments));
    }
    async(callback) {
      Ember.run.join(() => Ember.run.schedule('actions', callback));
    }
    reportUncaughtRejection(error) {
      Ember.run.next(null, function () {
        if (Ember.onerror) {
          Ember.onerror(error);
        } else {
          throw error;
        }
      });
    }
    defer() {
      return Ember.RSVP.defer();
    }
    globalDebuggingEnabled() {
      return Ember.ENV.DEBUG_TASKS;
    }
  }
  _exports.EmberEnvironment = EmberEnvironment;
  const EMBER_ENVIRONMENT = new EmberEnvironment();
  _exports.EMBER_ENVIRONMENT = EMBER_ENVIRONMENT;
});