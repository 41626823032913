define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de-de", {
    "accountTypes": {
      "any": "Any",
      "paying": "Paying",
      "testing": "Testing"
    },
    "actions": {
      "double_click_to_edit": "Double click to edit"
    },
    "additional_cities": {
      "allow": "Ja",
      "inherit": "Inherit from variant",
      "label": "Erlaubt weitere Einsatzorte",
      "prohibit": "Nein"
    },
    "bool_answer": {
      "no": "Nein",
      "yes": "Ja"
    },
    "buttons": {
      "back": "Back",
      "cancel": "Cancel",
      "clear": "clear",
      "clear_all": "Clear all",
      "close": "Close",
      "confirm": "Are you sure?",
      "delete": "Delete",
      "no": "Nein",
      "reset_search": "Reset search results",
      "save": "Save",
      "view_details": "View details",
      "yes": "Ja"
    },
    "companies": {
      "back_to_parent": "Zurück zu vorheriger Liste",
      "children": {
        "intermediary": "Zwischengesellschaften von",
        "subsidiary": "Tochtergesellschaften von"
      },
      "sidebar": {
        "headline": "Unternehmen",
        "load_more_button": "Weitere laden",
        "pagination": "{loadedItems, number} von {totalItems, number}"
      }
    },
    "company": {
      "career_site_jobs": {
        "disabled": "Disable",
        "enabled": "Enable",
        "intermediary": "Inherit from intermediary",
        "partner": "Inherit from partner",
        "umbrella": "Inherit from holding"
      },
      "fields": {
        "career_site_jobs": "Career site jobs feature",
        "disable_indeed_posting_extension": "Disable Indeed postings extension",
        "id": "Company ID",
        "indeed_email": "Indeed E-Mailadresse",
        "indeed_sourcename": "Indeed Source",
        "linkedin_profile_url": "LinkedIn Profil des Unternehmens",
        "linkedin_reference": "LinkedIn Company ID",
        "partner_name": "Partner",
        "xing_profile_url": "XING Profil des Unternehmens"
      },
      "form": {
        "probation": "Probation",
        "warn_about_disabled_csjs_feature": "After saving career site postings of companies which inherit access to career site jobs from this company will be expired.",
        "warn_about_inherited_fcs": "This intermediary has {count} children with active shared frame contracts. After submitting the form, they will loose access to them.",
        "warnings": {
          "source_changed": "Please make sure to inform Indeed about the change of the source name and that budgets and campaigns need to be adjusted by Indeed."
        }
      },
      "frame_contracts": {
        "intermediary": "Show intermediary frame contracts",
        "label": "Frame contracts",
        "own": "Own frame contracts",
        "umbrella": "Show holding frame contracts"
      },
      "general_info": "Jobbörsen Informationen",
      "inherits_from": "Inherits from",
      "level": "Type",
      "levels": {
        "individual": "Individual",
        "intermediary": "Intermediary",
        "subsidiary": "Subsidiary",
        "umbrella": "Holding"
      },
      "select": {
        "intermediary": "Select Intermidiary",
        "umbrella": "Select Holding"
      },
      "share_fcs": {
        "all_intermediaries_and_subsidiaries": "All intermediaries and subsidiaries",
        "all_subsidiaries": "All subsidiaries",
        "direct_subsidiaries": "All direct subsidiaries",
        "failure": "{count} subsidiaries did not inherit this frame contract, as they did already have another contract for the same product.",
        "intermediaries": "All intermediaries",
        "no_subsidiaries": "No subsidiaries",
        "scope": "Inherit to",
        "success": "{count} subsidiaries have successfully inherited",
        "warning_about_scope": "{count} subsidiaries won't inherit this frame contract, as they do already have another contract for the same product."
      },
      "show_all_fcs": "Show all frame contracts",
      "show_intermediaries": "Show all intermediaries",
      "show_own_fcs": "Show own frame contracts",
      "show_subsidiaries": "Show all subsidiaries",
      "status": {
        "advance_payment": "Advance payment",
        "label": "Status",
        "no_restriction": "No restriction",
        "on_probation": "On probation",
        "suspended": "Suspended"
      },
      "structure": "Firmenstruktur",
      "toggle_fcs": "Un-/select all",
      "tracking": {
        "apply_url": "Apply link",
        "cancel": "Cancel",
        "career_site_url": "Career site link",
        "company_id": "Company id",
        "external": "Via external fields",
        "full": "Full Tracking",
        "header_image_url": "Header image",
        "information": "Tracking information",
        "job_url": "Job URL",
        "logo_uri": "Company logo",
        "none": "No tracking",
        "pixel_url": "Tracking pixel",
        "save_disabled": "The parent form {parent} must be saved first"
      }
    },
    "components": {
      "country_select": {
        "all": "All countries",
        "europe": "Europe",
        "placeholder": "Please, select countries"
      },
      "email_options": {
        "include_html": "Always include posting HTML"
      },
      "errors_overview": {
        "title": "Irgendetwas ist schief gelaufen. Siehe:"
      },
      "inherited_company_field": {
        "apply_sourcename_change_to_all_postings": "Should the sourcename change be applied to already existing postings?",
        "intermediary": "Von Zwischengesellschaft übernommen",
        "other": "Sonstiges",
        "umbrella": "Von Muttergesellschaft übernommen"
      },
      "partners_select": {
        "placeholder": "Please, select partners"
      }
    },
    "customizations": {
      "background_color": "Liquid Design Hintergrundfarbe",
      "background_image_url": "URL für Hintergrundbild",
      "headline": "Individualisierung",
      "headline_color": "Liquid Design Überschriftenfarbe",
      "second_header_image_url": "URL für zweites Header Image",
      "third_header_image_url": "URL für drittes Header Image"
    },
    "cycle_time": {
      "inherit": "Inherit",
      "more_than_three_days": "Mehr als 3 Tage",
      "not_available": "Nicht verfügbar",
      "one_or_two_days": "1 - 2 Tage",
      "same_day": "Innerhalb von einem Tag",
      "two_or_three_days": "2 - 3 Tage"
    },
    "date": {
      "all_time": "All dates",
      "apply": "Apply",
      "custom_range": "Custom range",
      "last_30_days": "Last 30 days",
      "last_7_days": "Last 7 days",
      "last_month": "Last month",
      "this_month": "This month",
      "today": "Today",
      "yesterday": "Yesterday"
    },
    "delivery": {
      "capi_credentials": {
        "public_key": "Public key",
        "reference_id": "Reference id",
        "secret_key": "Secret key"
      },
      "configuration": {
        "cancel": "Abbrechen",
        "capi_credentials": "credentials",
        "editable_hint": "Only editable for new and draft items",
        "not_required": "This adapter does not require configuration",
        "save": "Speichern",
        "save_disabled": "Der {parent} muss zuerst gespeichert werden.",
        "title": "Lieferinformationen"
      },
      "generic_feed": {
        "credentials_required": "Credentials required?",
        "password": "Password",
        "url": "Url for the feed",
        "username": "Username"
      },
      "linkedin_premium": {
        "enabled": "Customer authorized GOhiring",
        "password": "Password",
        "requested": "Authorization failed",
        "status": "Integration status",
        "unknown": "Authorization pending",
        "username": "Username",
        "widget": "LinkedIn Authentication URL"
      },
      "methods": {
        "common_api": "Common API",
        "email": "Email",
        "generic_feed": "Generic Feed",
        "indeed": "Indeed",
        "linkedin": "LinkedIn Basic",
        "linkedin_p4p": "LinkedIn P4P",
        "linkedin_premium": "LinkedIn Premium",
        "stepstone": "StepStone",
        "xing": "XING"
      },
      "xing": {
        "organization_id": "Orga ID",
        "product_type": "Produkt",
        "product_types": {
          "basic": "Basic",
          "basic_plus": "Basic+",
          "campus": "Campus",
          "professional_html": "Professional HTML",
          "professional_plus": "Professional+",
          "standard": "Standard"
        },
        "xing_id": "Order ID"
      }
    },
    "delivery_methods": {
      "adapter": "Adapter/Feed",
      "email": "Posting email recipient"
    },
    "environments": {
      "live": "Live",
      "sandbox": "Sandbox"
    },
    "errors": {
      "delete_failed": "Could not delete record.",
      "request_failed": "Request failed.",
      "server_failed": "The action failed to execute. Looks like a bug.",
      "server_rejected": "That action is not allowed for this record."
    },
    "feed_configuration": {
      "feed_all": "One feed for all customers",
      "feed_client": "One feed per customer",
      "feed_inherit": "Inherit from variant",
      "feed_partner": "One feed per partner",
      "header_image_url": "Header image URL",
      "job_external_id": "External Job ID",
      "location_external_id": "External Location ID",
      "title": "Feed"
    },
    "filter_company": {
      "prompt": "Company"
    },
    "filter_holding": {
      "prompt": "Holding"
    },
    "filter_item": {
      "product_name_prompt": "Item"
    },
    "filter_owner": {
      "prompt": "Partner"
    },
    "filter_provider": {
      "prompt": "Provider"
    },
    "filter_supplier": {
      "prompt": "Supplier"
    },
    "frame_contract": {
      "actions": {
        "duplication": "Duplicate frame contract",
        "edit": "Edit",
        "new_successor": "Create successor frame contract"
      },
      "activation_warnings": {
        "flatrate": "Sind alle Werte zur Lieferung und für die Rechnungsstellung korrekt? Nach dem Speichern wird pro Buchung eine Rechnung an den Partner {partner} erstellt mit einer Postinggebühr von {discountedFee} je Buchung und der Endkunde kann über den Rahmenvertrag buchen."
      },
      "active_subscription": "This must be a marketplace frame contact as this company has an active SaaS subscription for the selected supplier",
      "buttons": {
        "delete": {
          "confirm": "Löschen bestätigen",
          "warning": "Bitte bestätige das Löschen des Rahmenvertrages. Dieser Vorgang kann nicht rückgängig gemacht werden"
        }
      },
      "delivery_info": "Delivery information",
      "delivery_info_warning": "Der Rahmenvertrag ist bereits \"aktiv\" und es wurde eine Änderung an den Lieferungsinformationen durchgeführt? Nach dem Speichern werden diese Änderungen sofort angewandt und müssen daher korrekt sein.",
      "disabled_shop_warning": "Option \"Disable shop products\": Though not specified in this frame contract, this supplier's shop products will not be displayed to the customer. Reason for this is another active frame contract between the same supplier and customer.",
      "entity_name": "Frame contract",
      "external_communication": "Externe Kommunikation",
      "fields": {
        "amount": {
          "postings": "Postings",
          "slots": "Slots"
        },
        "code": "Rahmenvertrags-CODE",
        "company": "Company",
        "company_id": "Company ID",
        "contracting_party": "Contracting Party",
        "current_state": "Aktueller Status",
        "cycle_time": "Durchlaufzeit",
        "description": "Description",
        "edit_information": "Edit Informationen",
        "email": "Posting email recipient",
        "environment": "Environment",
        "fee": "Postinggebühr Endkunde",
        "fee_discount": "Discount (Share partner) in %",
        "id": "Rahmenvertrags-ID",
        "initial_amount": "initial",
        "internal_information": "Internal information",
        "internal_notes": "Internal notes",
        "invoice_sales_price": "Include on invoice",
        "marketplace": "Marketplace Rahmenvertrag",
        "name": "Channel name replacement",
        "new_state": "Change status to",
        "other": "Other",
        "partner": "Partner",
        "partner_fee": "Postinggebühr Partner",
        "posting_email": "Proposed posting email recipient",
        "posting_price": "Preis pro Posting für Endkunde",
        "predecessor_id": "Predecessor FC",
        "pricing_type": "Type",
        "product": "Product",
        "provides_feedback": "Posting Status",
        "refresh_amount": "Anzahl Refresh",
        "reminder_email_recipient": "Reminder email recipient",
        "sales_price": "Set sales price",
        "same_as_posting_email": "Same as posting email recipient",
        "share_payment_in_postings": "Rechnungsinformationen senden",
        "shop_disabled": "Disable shop products",
        "supplier": "Jobbörse",
        "total_amount": "available",
        "use_upcs": "Use UPCs"
      },
      "form": {
        "company": {
          "prompt": "Select company"
        },
        "partner": {
          "prompt": "Select partner"
        },
        "supplier": {
          "prompt": "Select existing supplier"
        },
        "variant": {
          "prompt": "Select product"
        }
      },
      "general_info": "General Information",
      "heirs_of_successor_warning": "Please note that this successor FC will be inherited to the same companies as its predecessor. The inheriting companies can be changed in the company view if this is not intended.",
      "invalid_target_fee_error": "Der Wert muss größer als 0 und kleiner oder gleich dem Wert der Postinggebühr für den Endkunden sein",
      "link_children_warning": "Please remember to link the frame contract to the related subsidiaries and intermediaries (if applicable).",
      "linkedin_premium_warning": "This customer must authorize GOhiring to post jobs on their behalf via their contract. Please save the frame contract with the status \"requested\" and share the LinkedIn Authentication URL and credentials with the customer.",
      "missing_indeed_contract": "This customer does not have an active Indeed campaign yet. Please remember to add an Indeed campaign in company view.",
      "missing_upcs_warning": "This customer does not have a UPC quota yet. Please remember to add a valid UPC quota in company view.",
      "no_free_job_slots_warning": "No job slots available. One live posting must be canceled before another can be published",
      "no_owner_warning": "Please select a company, or set the frame contract to partner-owned.",
      "partner_owned": "Partner Owned",
      "posting_feedback": {
        "inherit": "Inherit",
        "none": "Submitted",
        "provides": "With feedback"
      },
      "posting_price": "Postinggebühr",
      "state": "Status",
      "supply_frame_contract_code_to_supplier_warning": "If the supplier is the invoicing party, please provide them with the frame contract code. This will enable them to assign all postings to the correct customer.",
      "support_tickets": "Support Tickets",
      "types": {
        "flat": "Flatrate",
        "quota": "Contingent",
        "slot": "Job slots"
      }
    },
    "frame_contracts": {
      "filters": {
        "id": "Search by FC ID",
        "include_inherited": "Include inherited contracts"
      },
      "flash": {
        "destroyed": "Der Rahmenvertrag wurde gelöscht"
      },
      "list": {
        "amount": "Kontingent",
        "expires_at": "Vertragsende",
        "state": "Status"
      },
      "page_headers": {
        "duplication": "Duplicate frame contract",
        "new": "New frame contract",
        "new_successor": "New frame contract (successor)"
      },
      "sidebar": {
        "headline": "Frame contracts",
        "load_more_button": "Weitere laden",
        "pagination": "{loadedItemsCount, number} von {totalItemsCount, number}"
      }
    },
    "indeed": {
      "budget": "Budget",
      "budget_interval": "Budget Interval",
      "budget_intervals": {
        "monthly": "Monthly",
        "onetime": "One-time",
        "yearly": "Yearly"
      },
      "campaign_frame_contracts": {
        "empty": "No linked frame contracts yet.",
        "per_job": "Budget per-job",
        "select_frame_contract": "Linked frame contract",
        "shared": "Shared budget"
      },
      "campaigns": {
        "end_date": "End date",
        "refund": "Refund",
        "show_deleted": "Show deleted segments",
        "show_used": "Show used segments",
        "start_date": "Start date",
        "status": "State"
      },
      "contracts": {
        "changed_since": "Changes effective from",
        "current_status": "Current state",
        "customer_discount": "Customer discount (%)",
        "empty": "No campaigns yet.",
        "name": "Name",
        "partner_share": "Partner share (%)",
        "status": "State",
        "title": "Indeed Campaign"
      },
      "employer": {
        "select": "Select employer",
        "title": "Employer / Subaccount"
      },
      "end_date_types": {
        "fixed": "Fixed",
        "target": "Target"
      }
    },
    "index": {
      "graphs": {
        "avg_postings": {
          "headline": "Durchschnittliche Anzahl Postings pro Bestellung"
        },
        "avg_revenue": {
          "headline": "Durchschnittlicher Umsatz pro Bestellung"
        },
        "orders": {
          "headline": "Orders"
        },
        "revenue": {
          "headline": "Umsatz"
        }
      },
      "leaderboard": {
        "customers": {
          "headline": "Kunden",
          "notes": "Basierend auf der Anzahl der Bestellungen, nicht des Umsatzes"
        },
        "headline": "Bestenliste",
        "job_boards": {
          "headline": "Jobbörsen",
          "notes": "Basierend auf der Anzahl der Posings"
        },
        "partners": {
          "headline": "Partner",
          "notes": "Basierend auf der Anzahl der Bestellungen, nicht des Umsatzes"
        }
      },
      "page_header": {
        "headline": "Source of Excitement",
        "notes": "Statistiken werden bald zurück sein"
      }
    },
    "invoice": {
      "confirmed_at": "Order confirmed at:",
      "cost_center": "Kostenstelle",
      "costs_subtotal": "Summe Einkaufspreise",
      "frame_contract": {
        "company": "Using frame contract {code}.",
        "no_invoice": "(hidden on invoice)",
        "partner": "Using partner frame contract {code}.",
        "sales_price": "From FC"
      },
      "headline": "Rechnungsdetails",
      "line_items": {
        "cancelled": "(storniert)",
        "costs": "Einkaufspreis pro Einheit",
        "description": "Beschreibung",
        "unit_price": "Verkaufspreis pro Einheit"
      },
      "subtotal": "Zwischensumme",
      "tax": "Steuern",
      "total": "Rechnungsbetrag"
    },
    "item": {
      "form": {
        "cancel_all": "Alles abbrechen",
        "product": {
          "new": "New product",
          "prompt": "Select a product"
        },
        "provider": {
          "new": "New provider",
          "prompt": "Select existing provider"
        },
        "supplier": {
          "logos": {
            "header": "Logo",
            "new": "You must save the supplier before adding logos."
          },
          "new": "New supplier",
          "prompt": "Select existing supplier"
        },
        "variant": {
          "new": "New variant"
        }
      },
      "index": {
        "delivery": {
          "headline": "Lieferinformationen",
          "missing": "Delivery information missing!"
        },
        "headline": "Item",
        "product": {
          "ad_type": "Anzeigentyp",
          "code": "Product Code",
          "country": "Länder",
          "description": "Beschreibung",
          "headline": "Produktinformation",
          "id": "Product ID",
          "inventory_amount": "Laufzeit",
          "list_price": "Listenpreis",
          "name": "Produktname",
          "niche": "Niche",
          "seniorities": "Karrierelevel",
          "stepstone_product_type": "Type of StepStone Product",
          "target_groups": "Zielgruppe"
        },
        "provider": {
          "common_api_url": "API URL",
          "due_date": "Zahlungsfrist vom Provider",
          "headline": "Providerinformationen",
          "id": "Provider ID",
          "internal_due_date": "Interne Zahlungsfrist für Provider",
          "name": "Provider Marke",
          "service_email": "Posting E-Mail Adresse"
        },
        "supplier": {
          "headline": "Supplierinformation"
        },
        "variant": {
          "excluded_partners": "Excluded partners",
          "headline": "Varianteninformation",
          "possible_status": "Posting Status"
        }
      },
      "search": {
        "placeholder": "Suche Item"
      }
    },
    "items": {
      "new": {
        "page_header": {
          "headline": "New Item"
        }
      },
      "sidebar": {
        "headline": "Items",
        "load_more_button": "Weitere laden",
        "pagination": "{loadedItemsCount, number} von {totalItemsCount, number}"
      }
    },
    "job": {
      "application_email": "Application Email",
      "application_url": "Application URL",
      "categorization": {
        "category": "Berufsfeld",
        "classification": "Vertragsart",
        "department": "Abteilung",
        "headline": "Kategorisierung",
        "industry": "Branche",
        "occupations": "Tätigkeiten",
        "schedule": "Arbeitszeit",
        "seniority": "Karrierelevel",
        "skills": "Schlagwörter",
        "years_of_experience": "Berufserfahrung in Jahren"
      },
      "company": {
        "career_site": "Karriereseite",
        "site": "Unternehmens Website"
      },
      "descriptions": {
        "headline": "Beschreibungen"
      },
      "designed_ad": {
        "headline": "Design-Stellenanzeigen",
        "uri": {
          "available": "Designte Stellenanzeige ist hier verfügbar: '<'a href=\"{uri}\" target=\"_blank\"'>'{uri}'</a>'.",
          "not_available": "Es wurden keine HTML-Anhänge hinzugefügt."
        }
      },
      "external_id": {
        "headline": "Externe ID"
      },
      "group": "Jobgruppe",
      "header_image": {
        "headline": "Header image"
      },
      "location": {
        "address": "Address",
        "external_location_id": "External Location ID",
        "headline": "Einsatzort"
      },
      "opening_count": "Geplante Einstellungen",
      "remote_types": {
        "full": "Fully remote",
        "not_remote": "Not remote",
        "partial": "Partially remote"
      },
      "salary": {
        "currency": "Währung",
        "headline": "Gehalt",
        "max": "Gehaltsobergrenze",
        "min": "Gehaltsuntergrenze",
        "period": "Zeitraum"
      },
      "starts_at": {
        "headline": "Earliest Start Date"
      },
      "url": "Job URL",
      "video": {
        "headline": "Video URL"
      }
    },
    "jobBoardCosts": {
      "any": "Paid & Free",
      "free": "Free",
      "paid": "Paid"
    },
    "key_as_it_is": "{key}",
    "login": {
      "headline": "GOhiring Dashboard",
      "login_button": "Sign in"
    },
    "order": {
      "attribute": {
        "company_id": "Company ID",
        "company_name_at_order_time": "Name zum Zeitpunkt der Buchung",
        "customer": "Customer",
        "holding": "Holding (intermediary)",
        "id": "Order ID",
        "job_id": "Job ID",
        "partner": "Partner"
      },
      "nav": {
        "invoice": "Rechnung",
        "job": "Stellenanzeige",
        "postings": "Veröffentlichungen"
      },
      "pending_review": {
        "approve": "Approve this Order",
        "confirm": "Yes"
      }
    },
    "orders": {
      "pending_review": "Pending review",
      "search": "Search",
      "sidebar": {
        "headline": "Orders",
        "load_more_button": "Weitere laden",
        "pagination": "{loadedItems, number} von {totalItems, number}"
      },
      "suspended": "Company suspended"
    },
    "partner": {
      "fields": {
        "branding_theme": "Vorlage",
        "career_site_jobs_enabled": "Career site feature (enabled for all customers)",
        "customer_line_item": "Rechnungsinformation vom Endkunden ausweisen (Extra Line Item)",
        "email": "E-Mail Adresse",
        "ends_at": "To",
        "exclude_sales_share": "Rechnung an Endkunde (Partner Share nicht auf Rechnung ausweisen)",
        "fee": "Posting Fee",
        "invoice_city": "Stadt",
        "invoice_country": "Land",
        "invoice_due_date": "Due Date",
        "invoice_email": "Email Rechnungsadresse für Xero",
        "invoice_for_orders": "Invoice for orders",
        "invoice_partner_name": "Name",
        "invoice_postal_code": "Postleitzahl",
        "invoice_street": "Straße",
        "invoice_tax_number": "Umsatzsteuernummer",
        "name": "Name",
        "new_customer_probation": "Put new customer in probation",
        "sales_share": "Shares",
        "sales_share_bundle": "Partner Share for Bundles in %",
        "sales_share_default": "Default Partner Share for orders in %",
        "sales_share_reselling": "Partner Share for Direct Reselling in %",
        "shares": "Shares",
        "starts_at": "From",
        "tax_rate": "Tax Rate in %",
        "value": "Value (%)"
      },
      "form": {
        "account_type": {
          "headline": "Account Type",
          "paying": "Paying",
          "testing": "Testing"
        },
        "invoicing": {
          "branding_theme": {
            "prompt": "Please select a theme"
          },
          "headline": "Rechnungsadresse"
        },
        "page_header": {
          "headline": "Partner"
        },
        "warn_about_disabled_csjs_feature": "After saving career site postings of companies which inherit access to career site jobs from the partner will be expired."
      },
      "index": {
        "general": {
          "headline": "Information"
        },
        "invoicing": {
          "headline": "Rechnungsoptionen"
        },
        "not_confirmed": {
          "headline": "Account wurde noch nicht Bestätigt",
          "notes": "Der Account wurde am {createdAt, date} {createdAt, time, short} erstellt, {name} hat ihn jedoch noch nicht bestätigt."
        },
        "stats": {
          "confirmed_at": "Passwort gesetzt",
          "created_at": "Eingeladen",
          "last_sign_in_at": "Letzter Login",
          "never_signed_in": "Hat sich noch nicht eingeloggt",
          "no_confirmation": "Nicht gesetzt"
        },
        "tokens": {
          "headline": "API Keys"
        }
      },
      "tokens": {
        "edit": "Edit token",
        "environment": "Environment",
        "new": "New token",
        "new_secret_key": {
          "confirm": "Yes, generate a new secret key",
          "generate": "Generate a new secret key",
          "warn": "Once confirmed, a new secret key will be generated, the previous one will be invalidated immediately."
        },
        "note": "Note",
        "secret_key": "Secret key",
        "secret_key_warn": "Save the secret key in a secure place, it won't be shown anymore.",
        "token": "Access Token"
      }
    },
    "partners": {
      "new": {
        "page_header": {
          "headline": "Neuer Partner"
        }
      },
      "sidebar": {
        "headline": "Partner",
        "load_more_button": "Weitere laden",
        "pagination": "{loadedItems, number} von {totalItems, number}"
      }
    },
    "posting": {
      "buttons": {
        "cancel_warning": "Soll dieses Posting wirklich storniert werden? Bitte beachte, dass dadurch auch der Wert auf der Buchung angepasst wird und das Finance Team automatisch per Mail benachrichtigt wird, um ggf. die Rechnung anzupassen.",
        "confirm_cancellation": "Confirm \"cancelled\" status",
        "confirm_cancellation_request": "Confirm cancellation request",
        "confirm_expiration": "Confirm \"expired\" status",
        "confirm_expiration_request": "Confirm expiration request",
        "confirm_status_change": "Confirm \"posted\" status",
        "quota_warning": "This posting has consumed a frame contract quota. Please remember to reset the frame contract counter in case the supplier doesn't count this posting against it.",
        "redeliver_email": "Resend posting email",
        "request_cancel": "Request Cancellation",
        "request_expiration": "Request Expiration",
        "set_to_cancelled": "Set status to \"cancelled\"",
        "set_to_expired": "Set status to \"expired\"",
        "set_to_posted": "Set status to \"posted\""
      },
      "cancellation_explanation": "Explanation to be shared with the supplier",
      "cancellation_explanation_placeholders": {
        "booking_error_duplication": "Die Anzeige wurde versehentlich mehrfach gebucht. Bitte storniert nur diese Buchung (Posting ID {postingId}) und behaltet die zweite Buchung trotzdem live.",
        "booking_error_job_position": "Hier wurde versehentlich die falsche Stelle gebucht.",
        "booking_error_media_buying": "Die Anzeige wurde versehentlich als Einzelbuchung gebucht, obwohl der Kunde einen Rahmenvertrag hat.",
        "booking_error_product_selection": "Hier wurde versehentlich ein falsches Produkt ausgewählt.",
        "customer_request_unknown_reason": "Hier wurde versehentlich eine Buchung ausgelöst.",
        "supplier_response_time": "Die Veröffentlichung hat zu lange gedauert und ist daher nicht mehr relevant."
      },
      "cancellation_reason": "Cancellation reason",
      "descriptions": {
        "change_status": "Note that changing the posting status issued by GOhiring does not affect actual status of the posting on the supplier side.",
        "request_cancellation": "Send a cancellation request to the supplier. The selected reason will be used internally only. The explanation will be added to the posting email sent to the supplier.",
        "request_expiration": "Send an early expiration request to the supplier."
      },
      "edit": {
        "fields": {
          "expiresAt": "Expiration date",
          "url": "Posting URL"
        },
        "page_header": {
          "headline": "Edit posting for {productName}"
        }
      },
      "headers": {
        "change_status": "Change posting status",
        "request_cancellation": "Request to cancel posting",
        "request_expiration": "Request to expire posting"
      },
      "placeholders": {
        "expires_at": "Die Veröffentlichung endet um?"
      },
      "redeliver_email": {
        "errors": {
          "failed": "The email wasn't resend, please, contact engineers."
        },
        "sent": "The posting email was sent to the job board. Check the watcher's Inbox."
      },
      "retry": {
        "action": "Neuen Versuch starten",
        "errors": {
          "failed": "The operation cannot be retried.",
          "no_delivery": "The posting's details about a scheduled retry cannot be fetched."
        },
        "no_scheduled_operation": "Kein automatischer Versuch",
        "operation": "Letztes ausgelöstes Event",
        "send_now": "Jetzt senden",
        "time": "Nächster automatischer Versuch",
        "tooltip": "Neuen Versuch starten",
        "triggered": "Das Event wurde erfolgreich gesendet, es kann jedoch etwas dauern, bevor sich der Status aktualisiert."
      },
      "warnings": {
        "management_uri_missing": "The job board has not yet provided a Common API management URI for this posting.",
        "set_future_expiration": "Please set the expiration date in the future in order for the posting to be transitioned to the posted state and submit the form, then you can continue changing the state"
      }
    },
    "postings": {
      "labels": {
        "posting": "Posting",
        "product": "Product",
        "provider": "Provider",
        "runtime": "Runtime",
        "supplier": "Supplier",
        "variant": "Variant"
      },
      "line_items": {
        "actions": "Ändern",
        "cancelled": "Cancelled",
        "click": "click",
        "delivered_via_common_api": "Published via \"Common API\" at:",
        "delivered_via_email": "Gesendet an",
        "delivered_via_indeed": "Published via \"Indeed\":",
        "delivered_via_linkedin": "Published via \"Linkedin\":",
        "delivered_via_null_delivery": "Dieses Posting gehört zu einem Rahmenvertrag. Die Abwicklung dazu ist noch nicht geklärt.",
        "delivered_via_stepstone": "Veröffentlicht via \"Stepstone\" unter:",
        "delivered_via_xing": "Published via \"XING\":",
        "delivery_email_status": "status",
        "description": "Beschreibung",
        "edit_view": "Editieren",
        "expiration": "Gültig bis",
        "locked_hint": "This posting is locked. It cannot be modified",
        "not_click": "not click",
        "not_open": "not open",
        "notification_emails": "Other notification emails",
        "open": "open",
        "posted": "posted",
        "resend_campaign": "Resend campaign",
        "retry_posting": "Retry posting",
        "revisions": "Revisionen",
        "sent": "sent",
        "state_view": "Status ändern",
        "status": "Posting status",
        "support": "Support tickets",
        "toggle_lock": "Toggle Lock",
        "versions": "Indeed virtual posting versions"
      }
    },
    "product": {
      "attribute": {
        "ad_type": "Anzeigentyp",
        "code": "Product Code",
        "country": "Länden",
        "id": "Product ID",
        "inventory_amount": "Inventory Amount",
        "inventory_type": "Inventory Type",
        "list_price": "Listenpreis",
        "niche": "Niche",
        "seniorities": "Karrierelevel",
        "target_groups": "Zielgruppe"
      },
      "form": {
        "button": {
          "cancel": "Abbrechen",
          "save": "Speichern"
        },
        "cannot_save": "The supplier must be saved first.",
        "cannot_save_without_product_type": "Please select the stepstone product type in order to save the form.",
        "field": {
          "ad_type": {
            "headline": "Anzeigentyp",
            "prompt": "Select ad type..."
          },
          "code": "Product Code (wird autom. generiert, wenn leer)",
          "country": "Länder",
          "description": "Beschreibung",
          "inventory_amount": "Laufzeit",
          "inventory_type": {
            "default": "Tage",
            "headline": "Inventory Type",
            "prompt": "Select inventory type..."
          },
          "list_price": "Listenpreis",
          "name": "Name",
          "niche": "Niche",
          "seniorities": {
            "headline": "Karrierelevel"
          },
          "short_name": "Short name",
          "stepstone_product": "Type of StepStone Product",
          "target_groups": {
            "headline": "Zielgruppe"
          }
        },
        "fieldset": {
          "pricing": "Preise und Laufzeit"
        },
        "placeholders": {
          "select_some_countries": "Wählen Sie einige Länder aus..."
        },
        "stepstone_product_type": {
          "bluecollar": "Bluecollar",
          "free_post": "Free Post",
          "internship": "Internship",
          "premium": "Premium",
          "pro": "Pro",
          "pro_campus": "Pro Campus",
          "pro_plus": "Pro Plus",
          "pro_ultimate": "Pro Ultimate",
          "pro_xxl": "Pro XXL",
          "professional": "Professional",
          "select_brand": "Select Brand",
          "select_plus": "Select Plus",
          "select_plus_brand": "Select Plus Brand",
          "select_simple": "Select",
          "starter": "Starter"
        },
        "stepstone_product_warning": "Warning: this is a one time setting that can't be changed afterwards."
      },
      "inventory_type": {
        "click": "Click",
        "day": "Day"
      },
      "seniority": {
        "entry_level": "Berufseinsteiger",
        "experienced": "Berufserfahren",
        "senior_executive": "Vorstand",
        "student": "Student"
      },
      "target_group": {
        "general": "Generalist",
        "regional": "Regionalist",
        "specialist": "Spezialist",
        "uni": "Uni"
      }
    },
    "products": {
      "index": {
        "headline": "Products"
      }
    },
    "provider": {
      "attribute": {
        "common_api_url": "Common API",
        "due_date": "Zahlungsfrist vom Provider",
        "id": "Provider ID",
        "internal_due_date": "Interne Zahlungsfrist für Provider",
        "service_email": "E-Mail Adresse"
      },
      "form": {
        "buttons": {
          "cancel": "Abbrechen",
          "save": "Speichern"
        },
        "fields": {
          "delivery_options": {
            "common_api_url": "API URL",
            "headline": "Veröffentlichung",
            "service_email": "Posting E-Mail Adresse"
          },
          "due_date": "Zahlungsfrist vom Provider",
          "internal_due_date": "Interne Zahlungsfrist für Provider",
          "name": "Provider Marke"
        }
      },
      "index": {
        "delivery": "Veröffentlichung",
        "delivery_options": {
          "common_api_url": "Common API",
          "headline": "Veröffentlichung",
          "service_email": "E-Mail Adresse"
        },
        "encoding": {
          "headline": "Codierung"
        },
        "headline": "Information"
      },
      "menu": {
        "general_information": "General Information",
        "variants": "Variants"
      }
    },
    "providers": {
      "new": {
        "page_header": {
          "headline": "New Provider"
        }
      },
      "sidebar": {
        "headline": "Providers",
        "load_more_button": "Weitere laden",
        "pagination": "{loadedItemsCount, number} von {totalItemsCount, number}"
      }
    },
    "publication": {
      "additional_cities": "Weitere Einsazorte",
      "apply_email": "E-Mail-Adresse für Bewerbungen",
      "apply_to_order": "Apply changes to all ({count}) postings of this order",
      "apply_url": "Link zu Ihrem Bewerbungsformular",
      "buttons": {
        "abort": "Abbrechen",
        "edit_details": "Zurück zu den Jobinformationen",
        "edit_html": "HTML bearbeiten",
        "preview": "Vorschau",
        "save": "Speichern",
        "save_with_changed_html": "Confirm revision"
      },
      "categories": "Kategorien",
      "category": "Berufsfeld",
      "classification": "Vertragsart",
      "company_logo": "Company logo",
      "company_name": "Unternehmensname",
      "confirm_warn": "There are changes made to title or description texts but not the HTML which will be sent out for this or another posting of this order. Please check whether the HTML requires to be changed",
      "contact": "Kontakt",
      "delete_desc": {
        "confirm": "Confirm deletion",
        "warning": "Please confirm to delete the description."
      },
      "descs": {
        "index": "Index"
      },
      "errors": {
        "unique_desc_indexes": "Values for indexes must be unique. Please make sure each index field contains a unique value.",
        "unique_desc_types": "Values for description_types must be unique. Please make sure each description_type text field contains a unique value."
      },
      "external_id": "Externe ID",
      "form": {
        "placeholders": {
          "select_some_occupations": "Wählen Sie einige Berufe aus"
        }
      },
      "header_image": "Header image",
      "heads": {
        "descs": "Descriptions",
        "job_html": "Anzeigen-HTML bearbeiten",
        "job_info": "Jobinformationen",
        "opening_count": "Anzahl offene Stellen",
        "video": "Video"
      },
      "hints": {
        "add": "Add a new description"
      },
      "industry": "Branche",
      "job_url": "Link zum Job auf der Karriereseite",
      "linkedin_profile_url": "LinkedIn company profile url",
      "location": "Location",
      "location_city": "City",
      "location_country": "Country",
      "location_external_id": "Location External ID",
      "location_postal_code": "Postal code",
      "location_remote_type": "Remote type",
      "location_state": "Region",
      "location_street": "Street",
      "occupations": "Tätigkeiten",
      "opening_count": "Anzahl",
      "position": "Jobtitel",
      "salary": "Gehalt",
      "salary_currency": "Währung",
      "salary_max": "Gehaltsobergrenze",
      "salary_min": "Gehaltsuntergrenze",
      "salary_period": "Zeitraum",
      "salary_periods": {
        "hourly": "pro Stunde",
        "monthly": "Monatlich",
        "yearly": "Jährlich"
      },
      "schedule": "Arbeitszeit",
      "seniority": "Karrierelevel",
      "skills": "Schlagwörter",
      "starts_at": "Earliest Start Date",
      "video_url": "Video URL",
      "xing_profile_url": "XING company profile url",
      "years_of_experience": "Berufserfahrung in Jahren"
    },
    "revisions": {
      "accept": "Revision akzeptieren",
      "changes": {
        "field": "Feld",
        "value": "Neuer Wert"
      },
      "confirm_acceptance": "Annahme bestätigen",
      "confirm_rejection": "Ablehnung bestätigen",
      "created_at": "Erstellt am",
      "empty": "Noch keine Revisionen",
      "errors": {
        "action_failed": "Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal",
        "load_failed": "Revisionen können nicht abgerufen werden"
      },
      "headline": "Revisionen für",
      "hints": {
        "back_btn": "Klicken Sie hier, um die Beiträge erneut anzuzeigen",
        "row_click": "Klicken Sie hier, um den Unterschied anzuzeigen"
      },
      "id": "ID",
      "reject": "Revision ablehnen",
      "state": "Status",
      "states": {
        "accepted": "Revision accepted",
        "awaiting": "Awaiting revision",
        "ignored": "Revision ignored",
        "processing": "Processing revision",
        "rejected": "Revision rejected",
        "requested": "Revision requested"
      },
      "view_html": "Vorschau"
    },
    "sortOrders": {
      "createdAt": "New",
      "updatedAt": "Changed"
    },
    "states": {
      "active": "Active",
      "all": "All",
      "any": "Any status",
      "awaiting": "Awaiting",
      "cancelled": "Cancelled",
      "complete": "Complete",
      "draft": "Draft",
      "expired": "Expired",
      "new": "New",
      "pending": "Pending",
      "rejected": "Rejected",
      "requested": "Requested"
    },
    "subscriptions": {
      "add_setup_fee": "Add setup-fee?",
      "agency": "Agency",
      "agency_selection_not_needed": "The specific agency doesn't need to be selected",
      "all_suppliers": "All suppliers",
      "auto_renewal": "Auto-renewal",
      "base_price": "Subscription base price (€)",
      "cannot_deselect_auto_renew": "The auto-renewal can't be unselected, as a renewal subscription was generated already.",
      "company_name": "Company name",
      "confirm_manual_expiry": "Are you sure you want to expire this active subscription? This Action cannot be undone and all related frame contracts will be changed from \"Marketplace\" to \"UPC\"",
      "create_invoice": "create now",
      "created_at": "Created at",
      "csb": "Csb",
      "csb_subscription": "Career Site Booster Subscription",
      "current_state": "Current state",
      "discounted_base_price": "Profit from subscription (€)",
      "discounted_setup_fee": "Profit from setup fee (€)",
      "empty": "No subscriptions added",
      "ensure_framecontract_exists": "Make sure a frame contract is created for all suppliers that the customer wants to book.",
      "enter_if_available": "Enter if available",
      "expire_at": "Expires at",
      "has_active_frame_contract": "This company has an active frame contract for <strong>{name}</strong>. As soon as this subscription becomes active the frame contract will be automatically switched to a Marketplace frame contract",
      "impossible_to_add_setup_fee_for_renewal": "It is not possible to add a setup fee for renewals",
      "indeed": "Indeed Campaigns",
      "inform_finance_about_expiry": "Please inform finance about the changed expiry date.",
      "inform_finance_for_reimbursement": "If the status is changed to “expired” please get in touch with finance in case a partial reimbursement is required",
      "invoice_already_created": "The invoice for this subscription has been generated on the {invoiceCreatedAt, date}. When changes are saved on this subscription an email will be send to billing@gohiring.com with information about the changes.",
      "invoice_attention_to": "Attention to",
      "invoice_city": "City",
      "invoice_comment": "Invoice comment",
      "invoice_cost_center": "Cost center",
      "invoice_country": "Country",
      "invoice_created": "Invoice created",
      "invoice_email": "Email address for invoices",
      "invoice_end_customer": "Invoice end customer (Partner share won't show on invoice)",
      "invoice_has_been_created": "The invoice was generated manually. If it should be send out before the {startAt, date}, then get in touch with finance team.",
      "invoice_information": "Invoice information",
      "invoice_partner": "Invoice partner (Invoice address of the partner will be used)",
      "invoice_postal_code": "Postal code",
      "invoice_recipients": {
        "customer": "End customer",
        "none": "None",
        "partner": "Partner"
      },
      "invoice_street": "Street, Number",
      "invoice_to": "Invoice to",
      "invoice_vat": "VAT-ID",
      "job_board": "Job board",
      "manually_expired_at": "Manually expired at",
      "manually_rejected_at": "Manually rejected at",
      "marketplace_flag_warning": "This company has active UPC contingents for one or more selected suppliers. Please remember to manually remove the \"Marketplace\" flag from the UPC frame contracts",
      "max_suppliers": "Max. 3 suppliers",
      "new_customer_discount": "New customer discount (%)",
      "no_invoice": "No invoice",
      "no_invoice_warning": "You have selected \"No invoice\". Please note that this option is normally used for Personio customer subscriptions only.",
      "partner_share": "Partner share (%)",
      "posting_count": {
        "count": "Postings: {count}",
        "loading": "loading",
        "show": "Show posting count"
      },
      "pricing": "Pricing",
      "reject_renewal_first": "There is already a renewal. Please do first reject the renewal and then this active subscription.",
      "renewal": "Renewal",
      "runtime": "Runtime",
      "saas": "SaaS",
      "saas_subscription": "SaaS supplier",
      "setup_fee": "Setup fee (€)",
      "setup_new_customer_discount": "New customer discount (%)",
      "setup_partner_share": "Partner share (%)",
      "start_at": "Starts at",
      "state": "State",
      "suppliers": "Suppliers",
      "switch_state_to": "Switch state to",
      "upc": "UPC",
      "useful_information": "End customer information, offer number, ..."
    },
    "success": "Done",
    "supplier": {
      "acceptable_encoding": {
        "iso_8859_1": "ISO-8859-1",
        "utf_8": "UTF-8"
      },
      "delivery": {
        "common_api_url": "API URL",
        "service_email": "Posting E-Mail Adresse"
      },
      "fields": {
        "acceptable_encoding": "Encoding",
        "allow_counterpart": "Allow CSPs to be published along with premium ones",
        "allow_external_tracking": "Allow external tracking params",
        "common_api_url": "API URL",
        "id": "Supplier ID",
        "job_list_url": "Stellenausschreibungs Übersicht",
        "name": "Supplier Brand",
        "service_email": "Posting Email-Adresse",
        "terms_and_conditions_url": "Terms & Conditions URL",
        "tracking_source": "Posting Statistics Provider",
        "visible": "Visible for all customers"
      },
      "form": {
        "button": {
          "cancel": "Abbrechen",
          "save": "Speichern"
        }
      },
      "index": {
        "logo": {
          "button": {
            "remove": "Remove",
            "upload": "Upload",
            "view": "View"
          },
          "default": "Default Logo",
          "error": {
            "headline": "File cannot be uploaded because of following reasons:"
          },
          "headline": "Logo",
          "square": "Quadratischer Logo"
        }
      },
      "menu": {
        "general_information": "General Information",
        "products": "Products"
      },
      "tracking_source": {
        "gohiring": "GOhiring",
        "indeed": "Indeed",
        "linkedin": "LinkedIn"
      }
    },
    "suppliers": {
      "new": {
        "page_header": {
          "headline": "New Supplier"
        }
      },
      "sidebar": {
        "headline": "Suppliers",
        "load_more_button": "Weitere laden",
        "pagination": "{loadedItemsCount, number} von {totalItemsCount, number}"
      }
    },
    "support_ticket": {
      "new": "New support ticket"
    },
    "text_search": {
      "length_alert": "It must have at least {length} characteres"
    },
    "timeframe": {
      "absolute": "absolute",
      "days": "days",
      "end": "end",
      "hours": "hours",
      "last": "last",
      "minutes": "minutes",
      "months": "months",
      "relative": "relative",
      "start": "start",
      "weeks": "weeks",
      "years": "years"
    },
    "timestamp": "{stamp, date} {stamp, time, hhmmss}",
    "timestamps": {
      "activated_at": "Actual activation date",
      "activates_at": "Planned activation date",
      "created_at": "Created",
      "expired_at": "Actual expiry date",
      "expires_at": "Planned expiry date",
      "starts_at": "Starts"
    },
    "universal_posting_credits": {
      "caption": "Universal posting credits",
      "change_warning": "Es wird keine neue Rechnung erstellt für diese Änderungen. Bitte kontaktiere das Finance Team, um bereits getätigte Abrechnungen zu korrigieren.",
      "empty": "No UPCs yet",
      "expiry_reasons": {
        "exhausted_quota": "Kontingent erschöpft",
        "manual": "Manuell",
        "reached_expiry_date": "Ablaufdatum erreicht"
      },
      "fields": {
        "charge_setup_fee": "Einrichtungsgebühr abrechnen?",
        "created_at": "Erstellt am",
        "current_state": "Aktueller Status",
        "discounted_setup_fee": "Ermäßigte Einrichtungsgebühr (€)",
        "discounted_total": "Summe (€)",
        "expired_at": "Tatsächliches Ablaufdatum",
        "expires_at": "Geplantes Ablaufdatum",
        "expires_in": "Laufzeit (Tage)",
        "expiry_reason": "Grund",
        "initial_amount": "UPC initial",
        "new_state": "Ändere Status zu",
        "partner_discount": "Rabatt Partner (%)",
        "posting_price": "Preis pro Schaltung (€)",
        "setup_fee": "Einrichtungsgebühr (€)",
        "setup_fee_partner_discount": "Rabatt Partner (%)",
        "setup_fee_user_discount": "Rabatt Endkunde (%)",
        "state": "Status",
        "total_amount": "UPC aktuell",
        "total_price": "Paketpreis (€)",
        "user_discount": "Rabatt Endkunde (%)"
      },
      "states": {
        "active": "Aktiv",
        "expired": "Abgelaufen",
        "pending": "Wartend"
      }
    },
    "variant": {
      "appearance": {
        "none": "No restrictions",
        "private": "Only with frame contracts",
        "test": "Test"
      },
      "delivery_recipient": {
        "provider": "Provider",
        "supplier": "Supplier"
      },
      "fields": {
        "allows_additional_cities": "Erlaubt weitere Einsatzorte",
        "appearance": "Appearance",
        "career_site_integration": "Career site integration",
        "cost_free_job_board": "Cost free job board",
        "costs": "Kosten",
        "cycle_time": "Durchlaufzeit",
        "delivery_method": "Delivery Method",
        "delivery_recipient": "Delivery Recipient",
        "description": "Beschreibung",
        "edit_information": "Edit Informationen",
        "excluded_partners": "Excluded partners",
        "id": "Variant ID",
        "provides_feedback": "Möglicher Posting Status",
        "refresh_amount": "Anzahl Refresh",
        "sales_price": "Verkaufspreis",
        "state": "Status"
      },
      "form": {
        "button": {
          "save_with_changed_product": "Yes, save with the changed product",
          "switch_product": "Apply"
        },
        "cannot_submit": "The product and provider must be saved first.",
        "field": {
          "delivery_method": {
            "headline": "Delivery Method",
            "prompt": "Select method..."
          },
          "delivery_recipient": {
            "headline": "Delivery Recipient",
            "prompt": "Select recipient..."
          },
          "excluded_partners": {
            "headline": "Excluded partners",
            "prompt": "Select partners ..."
          },
          "product": {
            "headline": "Product",
            "prompt": "Select product..."
          },
          "provides_feedback": {
            "headline": "Möglicher Posting Status"
          },
          "state": {
            "archived": "Archiviert",
            "draft": "Entwurf",
            "headline": "Status",
            "locked": "Gesperrt",
            "published": "Veröffentlicht"
          },
          "supplier": {
            "headline": "Supplier",
            "prompt": "Select supplier..."
          },
          "tracking_source": {
            "gohiring": "GoHiring",
            "headline": "Statistics provider",
            "indeed": "Indeed",
            "inherit": "Inherit from supplier",
            "linkedin": "LinkedIn"
          }
        },
        "fieldset": {
          "pricing": "Preise und Laufzeit"
        },
        "product": {
          "existing": {
            "button": "Switch to another product...",
            "headline": "The variant is based on \"{product_name}\" product supplied by\n{supplier_name}.\n"
          },
          "new": {
            "button": "Select product for the variant...",
            "headline": "You must base this variant on one of many existing products.",
            "none": "Supplier has no products!",
            "placeholder": "Select a product"
          }
        },
        "types": {
          "career_site_integration": "Career site integration",
          "fc": "FC w/o Mediabuying",
          "placeholder": "Type",
          "public": "Public"
        }
      },
      "provides_feedback": {
        "false": "Submitted",
        "true": "With feedback"
      },
      "warnings": {
        "career_site": "Once saved, the career site integration flag cannot be changed.",
        "free_product_warning": "Please note: You are about to add a product that will be available to all customers free of charge, though not explicitly labeled as a free product.",
        "product_change": "Changing the product of an active variant should only be done in exceptional cases. Please make sure that this is really needed here and confirm this action.",
        "review_generic_feed_configuration": "Please check the Generic Feed settings before saving. Once it is published it cannot be modified again."
      }
    },
    "variants": {
      "index": {
        "headline": "Variants"
      }
    },
    "versions": {
      "buttons": {
        "delete": {
          "confirm": "Delete version",
          "warning": "Are you sure? This action cannot be undone."
        },
        "edit": "Edit",
        "new": "New version"
      },
      "empty": "No versions yet.",
      "errors": {
        "load_failed": "Virtual posting versions could not be loaded."
      },
      "header": "Versions of",
      "hints": {
        "row_click": "Click here to show differences from the base posting"
      },
      "list": {
        "city": "City",
        "created_at": "Creation date",
        "delete": "Delete",
        "edit": "Edit",
        "id": "ID",
        "position": "Position",
        "region": "Region"
      }
    }
  }]];
  _exports.default = _default;
});