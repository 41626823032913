define("ember-power-select/components/power-select/search-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="ember-power-select-options" role="listbox">
    <li class="ember-power-select-option ember-power-select-option--search-message" role="option">
      {{@searchMessage}}
    </li>
  </ul>
  */
  {
    "id": "p9iIhoP1",
    "block": "[[[10,\"ul\"],[14,0,\"ember-power-select-options\"],[14,\"role\",\"listbox\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--search-message\"],[14,\"role\",\"option\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@searchMessage\"],false,[]]",
    "moduleName": "ember-power-select/components/power-select/search-message.hbs",
    "isStrictMode": false
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});