define("ember-intl/-private/formatters/format-message", ["exports", "fast-memoize", "intl-messageformat", "ember-intl/-private/utils/parse"], function (_exports, _fastMemoize, _intlMessageformat, _parse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const {
    Handlebars: {
      // @ts-expect-error Upstream types are incomplete.
      Utils: {
        escapeExpression
      }
    }
  } = Ember;
  function escapeOptions(object) {
    if (typeof object !== 'object') {
      return;
    }
    const escapedOpts = {};
    Object.keys(object).forEach(key => {
      const val = object[key];
      if (Ember.String.isHTMLSafe(val)) {
        // If the option is an instance of Ember SafeString,
        // we don't want to pass it into the formatter, since the
        // formatter won't know what to do with it. Instead, we cast
        // the SafeString to a regular string using `toHTML`.
        // Since it was already marked as safe we should *not* escape it.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        escapedOpts[key] = val.toHTML();
      } else if (typeof val === 'string') {
        escapedOpts[key] = escapeExpression(val);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        escapedOpts[key] = val; // copy as-is
      }
    });

    return escapedOpts;
  }

  /**
   * @private
   * @hide
   */
  class FormatMessage {
    constructor(config) {
      _defineProperty(this, "config", void 0);
      _defineProperty(this, "readFormatConfig", void 0);
      _defineProperty(this, "createNativeFormatter", (0, _fastMemoize.default)((ast, locales, formatConfig) => {
        return new _intlMessageformat.default(ast, locales, formatConfig, {
          ignoreTag: true
        });
      }));
      this.config = config;

      // NOTE: a fn since we lazily grab the formatter from the config
      // as it can change at runtime by calling intl.set('formats', {...});
      this.readFormatConfig = config.readFormatConfig;
    }

    // ! Function overloads are not passed through generic types for reasons that
    // evade my knowledge. ¯\_(ツ)_/¯
    // For this reason these types need to be manually copied over to the
    // `IntlService#formatMessage`.
    format(locale, maybeAst, options) {
      let ast = maybeAst;
      if (typeof maybeAst === 'string') {
        // maybe memoize?  it's not a typical hot path since we
        // parse when translations are pushed to ember-intl.
        // This is only used if inlining a translation i.e.,
        // {{format-message "Hi {name}"}}
        ast = (0, _parse.default)(maybeAst);
      }
      const isHTMLSafe = options && options.htmlSafe;
      const formatterInstance = this.createNativeFormatter(ast, locale, this.readFormatConfig());
      const escapedOptions = isHTMLSafe ? escapeOptions(options) : options;
      const result = formatterInstance.format(escapedOptions);
      return isHTMLSafe ? Ember.String.htmlSafe(result) : result;
    }
  }
  _exports.default = FormatMessage;
  _defineProperty(FormatMessage, "type", 'message');
});